<template>
  <div class="space-y-3">
    <div class="flex items-center gap-2">
      <div class="p-2 bg-pink-100 dark:bg-pink-900 rounded">
        <SvgUse
          name="ranking"
          path="/svgs/home.svg"
          class="w-6 h-6 text-pink-500 dark:text-white"
        />
      </div>
      <h4 class="text-xl font-bold">{{ LL.popular_topic() }}</h4>
    </div>
    <UDivider />
    <div class="flex flex-wrap gap-2">
      <div
        v-for="t in tags"
        :key="t.id"
        class="px-2 py-1.5 text-gray-900 dark:text-white text-sm rounded font-medium bg-gray-50 dark:bg-gray-950 hover:bg-gray-200 hover:dark:bg-gray-700 hover:text-gray-700 ring-1 ring-gray-300 dark:ring-gray-700 ring-inset"
      >
        <nuxt-link :to="`/posts/topics/${t.slug}`" class="space-x-1">
          <span>{{ t.title }}</span>
          <span class="text-gray-400 dark:text-gray-500">({{ t.count }})</span>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { TTopicPopular } from "~/features/post";
import { typesafeI18n } from "~/locales/i18n-vue";

type Props = {
  tags: TTopicPopular[];
};

withDefaults(defineProps<Props>(), {
  tags: () => [],
});

const { LL } = typesafeI18n();
</script>
